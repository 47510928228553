<template>
  <moe-page title="评价管理">
    <moe-card class="mb-20" :autoHeight="true" title="评价管理">
      <moe-inquire @search="reviewSearch">
        <el-form-item label="商品名称">
          <el-input v-model.trim="reviewParams.goodsName" placeholder="输入商品名称" maxlength="100" clearable />
        </el-form-item>
        <el-form-item label="评价星级">
          <moe-select type="goodsReviewStarList" v-model="reviewParams.star" placeholder="选择评价星级"></moe-select>
        </el-form-item>
        <el-form-item label="置顶状态">
          <moe-select type="goodsReviewPinList" v-model="reviewParams.pin" placeholder="选择置顶状态"></moe-select>
        </el-form-item>
        <el-form-item label="是否隐藏">
          <moe-select type="goodsReviewStateList" v-model="reviewParams.state" placeholder="选择是否隐藏"></moe-select>
        </el-form-item>
        <el-form-item label="发布时间" prop="startTime">
          <el-date-picker
            :picker-options="pickerOptions"
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([reviewParams.startTime, reviewParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-tabs type="border-card" :tabsList="[{ label: '全部评价' }, { label: '带图评价' }]" @change="handleChange">
        <template #0>
          <moe-table v-if="tabsIndex === 0" ref="reviewTableRef" url="/shop/goods/review/list" :params="reviewParams" :numberShow="false" :mode="mode" rowKey="id" @selectChange="selectChange">
            <!-- 操作按钮 -->
            <template slot="tool">
              <el-button type="success" icon="el-icon-top" @click="handleClick('pin', '批量置顶', true)">批量置顶</el-button>
              <el-button type="warning" icon="el-icon-bottom" @click="handleClick('pin', '批量取消置顶', false)">批量取消置顶</el-button>
              <el-button type="danger" icon="el-icon-thumb" @click="handleClick('state', '批量显示', 'SHOW')">批量显示</el-button>
              <el-button type="danger" icon="el-icon-thumb" @click="handleClick('state', '批量隐藏', 'HIDE')">批量隐藏</el-button>
            </template>
            <el-table-column type="selection" reserve-selection label="id" width="60" fixed="left" />
            <el-table-column label="商品信息" min-width="200">
              <template slot-scope="{ row }">
                <div class="df fdc">
                  <div class="fwb">{{ row.goodsName }}</div>
                  <div class="font-12 color-info">{{ row.goodsItemName }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="评价星级" min-width="80">
              <template slot-scope="{ row }">
                {{ `${row.star}星` }}
              </template>
            </el-table-column>
            <el-table-column label="评价内容" prop="content" min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="评价视频/图片" min-width="500">
              <div class="df aic jcc" slot-scope="{ row }">
                <template v-if="row.videos">
                  <div class="mr-10">
                    <video controls width="80px" height="80px" :src="row.videos"></video>
                  </div>
                </template>
                <template v-if="row.imgs">
                  <div class="mr-10" v-for="(item, index) in row.imgs.split(',')" :key="index">
                    <moe-image :src="item" width="80px" height="80px" />
                  </div>
                </template>
              </div>
            </el-table-column>
            <el-table-column label="订单编号" prop="orderNo" min-width="200" :show-overflow-tooltip="true" />
            <el-table-column label="用户名称" prop="userName" min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="发布时间" prop="createTime" min-width="150" />
            <el-table-column label="是否置顶" prop="pin" min-width="80" :formatter="formatterPin" />
            <el-table-column label="状态" min-width="80">
              <div :class="$moe_format.getGoodsReviewStateListColor(row.state)" slot-scope="{ row }">
                {{ $moe_format.getGoodsReviewStateList(row.state) }}
              </div>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="250">
              <div class="moe-table_btns" slot-scope="{ row }">
                <el-button type="warning" v-if="row.pin" size="mini" icon="el-icon-bottom" @click="req_updatePin([row.id], '取消置顶', false)">取消置顶</el-button>
                <el-button type="success" v-else size="mini" icon="el-icon-top" @click="req_updatePin([row.id], '置顶', true)">置顶</el-button>
                <el-button type="danger" v-if="['SHOW'].includes(row.state)" size="mini" icon="el-icon-thumb" @click="req_updateState([row.id], '隐藏', 'HIDE')">隐藏</el-button>
                <el-button type="danger" v-else size="mini" icon="el-icon-thumb" @click="req_updateState([row.id], '显示', 'SHOW')">显示</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </template>
        <template #1>
          <moe-table v-if="tabsIndex === 1" ref="reviewTableRef" url="/shop/goods/review/list" :params="reviewParams" :numberShow="false" :mode="mode" rowKey="id" @selectChange="selectChange">
            <!-- 操作按钮 -->
            <template slot="tool">
              <el-button type="success" icon="el-icon-top" @click="handleClick('pin', '批量置顶', true)">批量置顶</el-button>
              <el-button type="warning" icon="el-icon-bottom" @click="handleClick('pin', '批量取消置顶', false)">批量取消置顶</el-button>
              <el-button type="danger" icon="el-icon-thumb" @click="handleClick('state', '批量显示', 'SHOW')">批量显示</el-button>
              <el-button type="danger" icon="el-icon-thumb" @click="handleClick('state', '批量隐藏', 'HIDE')">批量隐藏</el-button>
            </template>
            <el-table-column type="selection" reserve-selection label="id" width="60" fixed="left" />
            <el-table-column label="商品信息" min-width="200">
              <template slot-scope="{ row }">
                <div class="df fdc">
                  <div class="fwb">{{ row.goodsName }}</div>
                  <div class="font-12 color-info">{{ row.goodsItemName }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="评价星级" min-width="80">
              <template slot-scope="{ row }">
                {{ `${row.star}星` }}
              </template>
            </el-table-column>
            <el-table-column label="评价内容" prop="content" min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="评价视频/图片" min-width="500">
              <div class="df aic jcc" slot-scope="{ row }">
                <template v-if="row.videos">
                  <div class="mr-10">
                    <video controls width="200px" height="200px" :src="row.videos"></video>
                  </div>
                </template>
                <template v-if="row.imgs">
                  <div class="mr-10" v-for="(item, index) in row.imgs.split(',')" :key="index">
                    <moe-image :src="item" width="80px" height="80px" />
                  </div>
                </template>
              </div>
            </el-table-column>
            <el-table-column label="订单编号" prop="orderNo" min-width="200" :show-overflow-tooltip="true" />
            <el-table-column label="用户名称" prop="userName" min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="发布时间" prop="createTime" min-width="150" />
            <el-table-column label="评价星级" min-width="80">
              <template slot-scope="{ row }">
                {{ `${row.star}星` }}
              </template>
            </el-table-column>
            <el-table-column label="是否置顶" prop="pin" min-width="80" :formatter="formatterPin" />
            <el-table-column label="状态" min-width="80">
              <div :class="$moe_format.getGoodsReviewStateListColor(row.state)" slot-scope="{ row }">
                {{ $moe_format.getGoodsReviewStateList(row.state) }}
              </div>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="250">
              <div class="moe-table_btns" slot-scope="{ row }">
                <el-button type="warning" v-if="row.pin" size="mini" icon="el-icon-bottom" @click="req_updatePin([row.id], '取消置顶', false)">取消置顶</el-button>
                <el-button type="success" v-else size="mini" icon="el-icon-top" @click="req_updatePin([row.id], '置顶', true)">置顶</el-button>
                <el-button type="danger" v-if="['SHOW'].includes(row.state)" size="mini" icon="el-icon-thumb" @click="req_updateState([row.id], '隐藏', 'HIDE')">隐藏</el-button>
                <el-button type="danger" v-else size="mini" icon="el-icon-thumb" @click="req_updateState([row.id], '显示', 'SHOW')">显示</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </template>
      </moe-tabs>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      reviewParams: {
        pageNum: 1,
        pageSize: 10,
        goodsName: '',
        star: '',
        pin: '',
        state: '',
        hasImg: false,
      },
      datetime: [],
      tabsIndex: 0,
      mode: false,
      selectList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date();
              end.setHours(0, 0, 0, 0);
              const start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '今天',
            onClick(picker) {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              const end = new Date();
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
        ]
      }
    }
  },
  methods: {
    formatterPin({ pin }) {
      return this.$moe_format.getGoodsReviewPinList(pin);
    },
    reviewSearch(isSearch) {
      this.mode = true;
      if (!isSearch) {
        this.reviewParams = {
          pageNum: 1,
          pageSize: 10,
          goodsName: '',
          star: '',
          pin: '',
          state: '',
          hasImg: this.tabsIndex ? true : false
        }

        this.datetime = [];
      }

      this.$refs['reviewTableRef'].searchData();
    },
    selectChange(list) {
      this.selectList = list;
    },
    handleChange({ index }) {
      this.mode = true;
      this.tabsIndex = Number(index);
      this.reviewSearch(false);
    },
    handleClick(source, name, params) {
      if (!this.selectList.length) {
        return this.$moe_msg.warning('请选择内容');
      }
      switch (source) {
        case 'pin':
          this.req_updatePin(this.selectList.map(({ id }) => id), name, params);
          break;
        case 'state':
          this.req_updateState(this.selectList.map(({ id }) => id), name, params);
          break;
      }
    },
    /** 更新商品评价置顶状态 */
    async req_updatePin(ids, name, pin) {
      this.$moe_layer.confirm(`您确定要"${name}"当前选项吗？`, async () => {
        const { code, message } = await this.$moe_api.REVIEW_API.updatePin({ pin, ids });
        if (code == 200) {
          this.$moe_msg.success(`${name}成功`);
          this.reviewSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      });
    },
    /** 更新商品评显示状态 */
    async req_updateState(ids, name, state) {
      this.$moe_layer.confirm(`您确定要"${name}"当前选项吗？`, async () => {
        const { code, message } = await this.$moe_api.REVIEW_API.updateState({ state, ids });
        if (code == 200) {
          this.$moe_msg.success(`${name}成功`);
          this.reviewSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      });
    },
  },
  mounted() {
    this.reviewSearch(false);
  }
}
</script>

<style lang="scss">

</style>